<template>
  <div>
    <div class="content-box">
      <div class="content-main">
        <el-form
          :model="form"
          :rules="rules"
          ref="form"
          :inline="true"
          :label-position="$config.labelPosition"
          :label-width="$config.labelWidth"
        >
          <el-row :gutter="10">
            <!-- 基本信息 -->
            <el-col :xs="24" :sm="24" :md="12" :lg="12" :xl="12">
              <el-form-item label="养护企业" prop="careUnitName">
                <el-select v-model="form.careUnitName" placeholder="请选择">
                  <el-option
                    v-for="item in careUnitNameOption"
                    :key="item.value"
                    :label="item.label"
                    :value="item.value"
                  ></el-option>
                </el-select>
              </el-form-item>
            </el-col>
            <el-col :xs="24" :sm="24" :md="12" :lg="12" :xl="12">
              <el-form-item label="标段名称" prop="bidId">
                <el-cascader
                  placeholder="请选择"
                  v-model="form.bidId"
                  :options="bidOptions"
                  @change="handleChange"
                  clearable
                ></el-cascader>
              </el-form-item>
            </el-col>
            <el-col :xs="24" :sm="24" :md="12" :lg="12" :xl="12">
              <el-form-item label="培训时间" prop="trainingTime">
                <el-date-picker
                  v-model="form.trainingTime"
                  type="datetime"
                  placeholder="选择日期时间"
                  value-format="timestamp"
                ></el-date-picker>
              </el-form-item>
            </el-col>
            <el-col :xs="24" :sm="24" :md="12" :lg="12" :xl="12">
              <el-form-item label="培训类型" prop="trainingType">
                <el-select v-model="form.trainingType" placeholder="请选择">
                  <el-option
                    v-for="item in $enums_hw.trainingTypeOption.list"
                    :key="item.value"
                    :label="item.label"
                    :value="item.value"
                    clearable
                  ></el-option>
                </el-select>
              </el-form-item>
            </el-col>
            <el-col :xs="24" :sm="24" :md="12" :lg="12" :xl="12">
              <el-form-item label="参与人数" prop="participantNum">
                <el-input v-model="form.participantNum" placeholder="请输入" maxlength="25"></el-input>
              </el-form-item>
            </el-col>
            <el-col :xs="24" :sm="24" :md="12" :lg="12" :xl="12">
              <el-form-item label="主持人" prop="host">
                <el-input v-model="form.host" placeholder="请输入" maxlength="25"></el-input>
              </el-form-item>
            </el-col>
            <el-col :xs="24" :sm="24" :md="24" :lg="24" :xl="24">
              <el-form-item label="备注">
                <el-input
                  v-model="form.remark"
                  type="textarea"
                  :rows="5"
                  placeholder="请输入"
                  maxlength="25"
                ></el-input>
              </el-form-item>
            </el-col>
            <el-col :xs="24" :sm="24" :md="24" :lg="24" :xl="24">
              <el-form-item label="培训内容资料" style="width: 100%">
                <ayl-upload-files v-model="contentInfoKey" :limit="3" :maxSize="10240" :accept="''"></ayl-upload-files>
              </el-form-item>
            </el-col>

            <el-col :xs="24" :sm="24" :md="24" :lg="24" :xl="24">
              <el-form-item label="人员签到名单">
                <ayl-upload-files v-model="checkInListKey" :limit="3" :maxSize="10240" :accept="''"></ayl-upload-files>
              </el-form-item>
            </el-col>

            <el-col :xs="24" :sm="24" :md="24" :lg="24" :xl="24">
              <el-form-item label="现场照片">
                <ayl-img-upload
                  v-model="livePicKeyImg"
                  :imgListLength="50"
                  :imgWidth="'130px'"
                  :imgHeight="'130px'"
                ></ayl-img-upload>
              </el-form-item>
            </el-col>
          </el-row>
        </el-form>
      </div>
      <div class="tac" style="margin-top: 40px">
        <el-button type="success" class="btn-success-hw" @click="onSubmit">提交</el-button>
        <el-button type="danger" class="btn-danger" style="margin-left: 10px" @click="onClose">关 闭</el-button>
      </div>
    </div>
  </div>
</template>

<script>
import addForDialog from "@/mixins/add-for-dialog";
export default {
  mixins: [addForDialog],
  props: {
    dataFromDialog: {
      //从弹窗获得传来的参数等数据，！！！！名称固定！！！！
      type: Object,
      default() {
        return {};
      }
    }
  },
  data() {
    return {
      nav: [{ name: "编辑安全培训记录" }],
      bidOptions: [],
      careUnitNameOption: [],
      form: {
        careUnitName: null, //养护企业
        bidId: null, //标段名称
        trainingTime: null, //培训时间
        trainingType: null, //培训类型
        participantNum: null, //参与人数
        host: null, //主持人
        remark: null, //备注
        contentInfoKey: [], //上传培训内容资料
        checkInListKey: [], //人员签到名单
        livePicKey: [] //现场照片url
      },
      livePicKeyImg: [],

      checkInListKey: [],
      contentInfoKey: [],
      // 必填字段校验
      rules: {
        careUnitName: {
          required: true,
          message: "必填字段不能为空",
          trigger: "change"
        },
        bidId: {
          required: true,
          message: "请选择标段",
          trigger: "change"
        },
        trainingType: {
          required: true,
          message: "请选择培训时间",
          trigger: "change"
        },
        trainingTime: {
          required: true,
          message: "请选择培训类型",
          trigger: "change"
        },
        participantNum: {
          required: true,
          message: "必填字段不能为空",
          trigger: "blur"
        },
        host: {
          required: true,
          message: "请输入车牌号码",
          trigger: "blur"
        }
      }
    };
  },
  methods: {
    handleChange(val) {
      this.form.bidId = val[1];
    },
    // 提交表单
    async submit() {
      let formData = JSON.parse(JSON.stringify(this.form));
      formData.contentInfoKey = this.contentInfoKey.map(item => {
        return JSON.stringify(item);
      });
      formData.checkInListKey = this.checkInListKey.map(item => {
        return JSON.stringify(item);
      });
      formData.livePicKey = this.livePicKeyImg;
      // 提交form数据
      await this.$api_hw
        .operationSafetyManagement_safetyTrainingUpdate({
          record: formData
        })
        .then(res => {
          BUS.$emit(BUSEVENT.REFRESH_SAFETT_TRAINING_RECORD);
        });
    }
  },

  async mounted() {
    // 获取标段
    this.bidOptions = await this.$api_hw.common_getBidNameAndIdList({});
    // 获取养护
    await this.$api_hw.districtInfo_getDistinctCareUnit({}).then(res => {
      this.careUnitNameOption = res.map(item => {
        return { value: item, label: item };
      });
    });

    // 获取详情
    this.form = await this.$api_hw.operationSafetyManagement_safetyTrainingGetDetail(
      { id: this.dataFromDialog.id }
    );

    this.contentInfoKey = this.form.contentInfoKey
      ? this.form.contentInfoKey.map(item => {
          return JSON.parse(item);
        })
      : [];
    this.checkInListKey = this.form.checkInListKey
      ? this.form.checkInListKey.map(item => {
          return JSON.parse(item);
        })
      : [];
    this.livePicKeyImg = this.form.livePicKey ? [...this.form.livePicKey] : [];
  }
};
</script>

<style lang='sass' scoped>
.content-box .content-main
  padding: 0 20px 20px
.content-box 
  padding: 0
/deep/ el-form
  display: flex;
  flex-wrap: wrap;
/deep/ .el-form-item
  display: flex;
  width: 100%;
/deep/ .el-date-editor.el-input,
/deep/ .el-range-editor--mini.el-input__inner,
/deep/ .el-cascader--mini,
/deep/ .el-select
  width: 100%;
/deep/ .el-form-item--mini .el-form-item__content
  flex: 1;
/deep/ .el-form-item--mini .el-form-item__label 
  text-align: right;
  width: 130px;
</style>